import { chatWxOpenId } from '@/services/api'
import { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { getToken, setPlat, setToken } from '../../utils/auth'

const Auth = () => {
	const [search] = useSearchParams()
	const navigate = useNavigate()

	const handlerCodeOpenId = async (wxcode: string, stateName: string) => {
		const { code, data } = await chatWxOpenId({ code: wxcode, state: stateName })
		if (code === 0) {
			setToken(data.openid);
		}
		if (getToken()) {
			window.location.href = "https://ghchat.guohualinks.com/"
		}

	}
	useEffect(() => {
		const token = search.get('token')
		const plat = search.get('plat')
		const state = search.get('state')
		const wxcode = search.get('code')

		if (!wxcode && token) {
			setPlat(plat || '')
			setToken(token || '')
			navigate('/')
		} else if (wxcode) {
			setPlat(state || '')
			handlerCodeOpenId(wxcode, state || '')
		}
	}, [search])

	return <div></div>
}

export default Auth
