import './App.css'
import ErrorBoundary from './components/ErrorBoundary'
import AuthenticatedApp from './AuthenticatedApp'
// import 
// import VConsole from 'vconsole';

const App = () => {
	// const vConsole = new VConsole();
	return (
		<div className='App'>
			<ErrorBoundary fallbackRender={() => <div>error</div>}>
				<AuthenticatedApp />
			</ErrorBoundary>
		</div>
	)
}

export default App
