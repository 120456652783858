import React, { useEffect, useRef, useState } from 'react';
import Chat, { Bubble, useMessages } from '@chatui/core';
import styles from './index.module.less'
import '@chatui/core/es/styles/index.less';
import '@chatui/core/dist/index.css';
import { chatStream, postChatTextToMedia, submitQuestion } from '@/services/api';
import riseInput from '@/utils/riseInput';
import { Toast, Button } from 'antd-mobile';
import { v4 as uuidv4 } from "uuid"
import { Icon } from '@chatui/core';
import { getPlat } from '@/utils/auth';
import RecordRTC, { MediaStream, StereoAudioRecorder } from 'recordrtc';
import classNames from 'classnames';


import CryptoJS from "crypto-js";
// import { btoa } from 'buffer';
// import WebSocket from "ws";
// import fs from "fs";

type ChatModal = {
  role: string;
  content: string;
}
// 在全球最具影响力的体育赛事中，NBA无疑是首屈一指的篮球联赛。它不仅拥有着顶尖的球员和激烈的比赛，还有着深厚的文化底蕴和丰富的历史。NBA每年吸引着无数的球迷，他们在电视前或球场内为自己喜欢的球队和球员加油鼓劲。图片描述：湖人队球员在比赛中上演精彩扣篮，掀起现场球迷的热烈气氛。
const initialMessages = [
  {
    type: 'text',
    content: { text: '您好，欢迎使用AI智询功能，现在您可以向我提出有关助残服务的相关问题了。 ', icon: false },
    user: { avatar: 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/ic_chatgpt_active.svg' },
  },
  // {
  //   type: 'image',
  //   content: {
  //     picUrl: 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/chatgpt-qa2.png',
  //   },
  //   user: { avatar: 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/ic_chatgpt_active.svg' },
  // },
];
const uu_id = uuidv4()

const GuoHuaChat = () => {
  const commentTime = useRef<number>(0);
  const { messages, appendMsg, setTyping, updateMsg } = useMessages(initialMessages);
  const [messageContent, setMessageContent] = useState<ChatModal[]>([]);
  const [isMearge, setIsMearge] = useState<boolean>(false);
  const MessageList = document.querySelector('.MessageList');
  const [isNone, setIsNone] = useState<boolean>(false);
  //停止生成
  const isStop = useRef<boolean>(false);

  //播放loading
  const [playLoad, setPlayLoad] = useState(false);



  const [recording, setRecording] = useState(false);
  const recorder = useRef<any>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const platChat = getPlat() === 'tyj';
  const mpChat = getPlat() === 'ghmp';
  const xytChat = getPlat() === 'xyt';

  const [currentPlayIndex, setCurrentPlayIndex] = useState<string | null>(null);

  // const markdown = (code: any) => {
  //   return marked(code);
  // }
  const handlerScrollEnd = () => {
    setTimeout(async () => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 500)
  }
  function findInArr(arr: any, n: any) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === n) return true;
    }
    return false;
  };
  const getByClass = (oParent: any, sClass: any) => {
    if (document.getElementsByClassName('')) {
      return oParent.getElementsByClassName(sClass);
    } else {
      var aEle = oParent.getElementsByTagName('*');
      var arr = [];
      for (var i = 0; i < aEle.length; i++) {
        var tmp = aEle[i].className.split(' ');
        if (findInArr(tmp, sClass)) {
          arr.push(aEle[i]);
        }
      }
      return arr;
    }
  }
  const updateBtyeMsg = (context: any) => {
    // context = markdown(context);
    var oUl = document.getElementById('root');
    var aBox = getByClass(oUl, 'Bubble text');
    if (aBox.length > 0) {
      aBox[aBox.length - 1].innerHTML = context;
      var msgList = getByClass(oUl, "PullToRefresh")[0];
      msgList.scrollTo(0, msgList.scrollHeight);
    }
    handlerScrollEnd();
  }

  // 得到返回消息
  const messageStream = async (msg: any, Id: string) => {
    const { code, data, message } = await chatStream(Id)
    if (code === 0) {
      if (!data.is_ended) {
        //超时处理
        const timesStemtamp = new Date().getTime();
        if (commentTime.current > 0 && (timesStemtamp - commentTime.current) / 1000 > 180 && (!data.message)) {
          setIsNone(false)

          Toast.show({
            content: '请求异常，请重新发送',
          })
          return
        }
        setIsNone(true)
        setTimeout(() => {
          console.log(isStop.current)
          if (!isStop.current) {
            messageStream(msg, Id)
          } else {
            setIsNone(false)
          }

        }, 500)
      } else {
        setIsNone(false)
      }
      msg.forEach((item: any, index: any) => {
        if (msg.length === index + 1 && item.role === "assistant" && data.message_id === Id) {
          item.content += data.message.trim().replace(item.content, '')
        }
      });
      setMessageContent([...msg])
      if (data.message.trim()) {
        updateBtyeMsg(data.message.trim());
      }
      window.scrollTo(0, document.body.scrollHeight);

      handlerScrollEnd();
    } else {
      Toast.show({
        content: message,
      })
    }
  }


  // 发送回调
  const handleSend = async (type: any, val: any) => {

    if (type === 'text' && val.trim() && !isNone) {
      const id = uuidv4()

      const msg = [...messageContent]
      if (msg.length > 0) {
        msg.push({ role: "user", content: val })
      } else {
        msg.push({ role: "system", content: val })
      }
      appendMsg({
        type: 'text',
        content: { text: val, icon: false },
        position: 'right',
        user: { avatar: 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/header-chat.svg' },
        // user: { avatar: '//gw.alicdn.com/tfs/TB1DYHLwMHqK1RjSZFEXXcGMXXa-56-62.svg' },
        appendId: uuidv4(),

      });
      setTyping(true);
      setIsNone(true)
      setMessageContent(msg)

      isStop.current = false

      // TODO: 发送请求
      setTimeout(async () => {
        //获取当前时间
        commentTime.current = new Date().getTime();
        const { code } = await submitQuestion(msg, id, uu_id)
        //获取消息数量放到  消息返回ture时调用
      }, 100)
      setTimeout(() => {
        msg.push({ role: "assistant", content: '' })

        appendMsg({
          type: 'text',
          appendId: id,//uuidv4(),
          content: { text: '', icon: true },
          position: 'left',
          user: { avatar: 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/ic_chatgpt_active.svg' },
        });

        messageStream(msg, id);
      }, 200)


    }

  }

  useEffect(() => {
    if (isNone) {
      handlerScrollEnd();
    }
  }, [isNone])

  useEffect(() => {

    if (platChat || mpChat) {
      document.title = "AI智询";
    } else {
      document.title = "国华ChatGPT";


    }
    const input = document.querySelector('textarea');
    const target = document.querySelector('form');
    // console.log(target)
    if (input && target) {
      riseInput(input, target)
    }
    if (MessageList) {
      // console.log(MessageList.clientHeight / window.innerHeight)
      if (MessageList.clientHeight / window.innerHeight > 0.5) {
        setIsMearge(true)
      } else {
        setIsMearge(false)
      }
    }

    window.addEventListener(
      'popstate',
      function (e) {
        //为了避免只调用一次
        onClickGoBack()
      },
      false
    )

  });

  //停止生成
  const handlerStopMsg = () => {
    isStop.current = true
    if (!messageContent.slice(-1)[0].content) {
      updateBtyeMsg("_")
    }
    handlerScrollEnd();
  }


  function renderMessageContent(msg: any) {
    const { type, content, position, appendId, _id, loading } = msg;
    // "ceosarmm63x2"
    // console.log(messages.slice(-1)[0]._id === _id)
    // console.log(messageContent.length, messageContent)
    // 根据消息类型来渲染
    // console.log(messages)
    switch (type) {
      case 'text':
        return <div>
          <Bubble content={content.text} >{content.icon && <Icon type="spinner" spin />}
            {/* !isNone position==='left' appendId && */}
          </Bubble>
          {/* <audio onEnded={handleAudioEnded} ref={audioRef} id={`audio-${_id}`} style={{ display: 'none', }} controls /> */}

          {appendId && !isNone && position === 'left' &&
            <div style={{ display: 'flex', marginTop: '5px', }} >
              {currentPlayIndex === appendId ? <div
                style={{ background: '#FEF9EB', padding: "5px", borderRadius: '10px', display: 'flex', }}
                onClick={stopMedia}>
                {/* <Icon style={{ fontSize: '20px' }} type="close-circle" /> */}
                {playLoad ? <Icon type="spinner" spin /> : <Icon style={{ fontSize: '20px' }} type="close-circle" />}
              </div> : <div
                // onClick={() => handlerClickStart(content, appendId, _id)}
                onClick={() => handlerClickText2Mp3(appendId)}
                style={{ background: '#FEF9EB', padding: "5px", borderRadius: '10px', display: 'flex' }}>
                <Icon style={{ fontSize: '20px' }} type="volume-circle" />
              </div>
              }
            </div>
          }
          {/* && isNone */}

          {appendId && position === 'left' && isNone && messages.slice(-1)[0]._id === _id && (messages.length === messageContent.length + 1) && <div
            style={{
              background: '#e1e1e1',
              borderRadius: '5px',
              padding: '3px 8px',
              width: '76px',
              fontSize: "14px",
              fontWeight: 'bold',
              textAlign: 'center',
              marginTop: '5px',
              color: "#6B74F5"
            }} onClick={() => handlerStopMsg()}>停止生成</div>}


        </div>
      case 'image':
        return (
          <Bubble type="image">
            <img src={content.picUrl} alt="" />
          </Bubble>
        );
      default:
        return null;
    }

  }



  const onClickGoBack = () => {
    //@ts-ignore
    wx.miniProgram.navigateBack({
      delta: 1
    })
  }
  const msgRef = useRef<any>(null);
  //滚动到底部
  const onInputFocus = () => {
    if (msgRef && msgRef.current) {
      msgRef.current.scrollToEnd();
    }
  }
  const onInputChange = async (e: any) => {

    if (e === 'voice') {
      await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      })
    }
  }
  // const onTextChange = async (e: any) => {
  //   console.log(e)
  // }


  //----------录音---------



  const RecordStart = async () => {

    try {
      const stream: MediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      })
      const recordRTC = new RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/pcm',
        recorderType: StereoAudioRecorder,
        numberOfAudioChannels: 1,
        desiredSampRate: 16000,
      });
      console.error('eeee');
      recordRTC.startRecording();
      console.error('ccccc');
      setRecording(true);
      recorder.current = recordRTC;
    } catch (error) {
      console.error('Error while starting recording:', error);
    }
  }
  const RecordStop = async () => {
    if (recorder.current) {
      recorder.current.stopRecording(() => {
        const audioBlob = recorder.current!.getBlob();

        // const link = document.createElement('a');
        // link.href = URL.createObjectURL(audioBlob);
        // link.download = 'audio.wav';
        // link.click();
        // console.log(audioBlob, "audioFile")
        const reader = new FileReader();
        reader.onload = () => {
          console.log(reader, 'redaer')
          const base64Data = reader.result as string;
          const fileLength = audioBlob.size;
          postWavRecord(base64Data, fileLength)
        };
        reader.onerror = (error) => {
          console.error('Error reading file:', error);
        };
        reader.readAsDataURL(audioBlob);

      });
      setRecording(false);
    }
  }

  //----------录音---------


  //--百度------------------------------
  const AK = "pcO95okxAWQm20axcTH2CVWf"
  const SK = "iTHpLHMCOFFvjXtdRHFrYWiMvKw0jHHW"

  /**语音转文字 */
  const postWavRecord = async (base64Data: any, fileLength: any) => {
    try {

      // console.log(base64Data, fileLength)
      // ?client_id=' + AK + '&client_secret=' + SK
      // /bd_server
      const response = await fetch('/server_api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          format: "pcm",
          rate: 16000,
          channel: 1,
          token: await getAccessToken(),
          dev_pid: 1537,
          speech: base64Data.replace('data:audio/wav;base64,', ''),
          len: fileLength,
          cuid: generateUniqueID(),
        })
      });
      if (response.ok) {
        const data = await response.json();
        console.log('success:', data);
        handleSend('text', data.result[0])
      } else {
      }
    } catch (error) {
    }
  }
  const getAccessToken = async () => {
    try {
      // /bd_api
      const response = await fetch('/oauth/2.0/token?client_id=' + AK + '&client_secret=' + SK + '&grant_type=client_credentials', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log('success:', data);
        return data.access_token;
      }
    } catch (error) {
    }
  }
  function generateUniqueID() {

    let userID = localStorage.getItem('userID');
    if (!userID) {
      userID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
      localStorage.setItem('userID', userID);
    }
    return userID;
  }

  //---百度文字转语音---
  //task_id 647ee652f4f4a10001233b0e
  const handlerClickStart = async (content: any, appendId: string, _id: string) => {
    // if (content.task_id) {
    //   handlerMediaPlay(content.task_id, _id)
    // } else {
    // try {
    // const response = await fetch('/rpc/2.0/tts/v1/create?access_token=' + await getAccessToken(), {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Accept': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     text: content,
    //     format: "mp3-16k",
    //     voice: 0,
    //     lang: "zh",
    //     speed: 5,
    //     pitch: 5,
    //     volume: 5,
    //     enable_subtitle: 0
    //   })
    // });
    // if (response.ok) {
    //   const data = await response.json();
    // updateMsg(_id, {
    //   type: 'text',
    //   content: { text: content.text, icon: false, task_id: data.task_id, },
    //   position: 'right',
    //   user: { avatar: 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/header-chat.svg' },
    //   appendId: appendId,
    // })
    //     handlerMediaPlay(data.task_id,_id)
    //   } else {
    //   }
    // } catch (error) {

    // }
    // }
  }
  const handlerMediaPlay = async (task_id: string, Id: string) => {

    // try {
    //   const response = await fetch('/rpc/2.0/tts/v1/query?access_token=' + await getAccessToken(), {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Accept': 'application/json',
    //     },
    //     body: JSON.stringify({
    //       task_ids: [task_id]
    //     })
    //   });
    //   if (response.ok) {
    //     const data = await response.json();
    //     if (data.tasks_info[0].task_status === 'Success') {
    //       playAudio(data.tasks_info[0].task_result.speech_url, Id)
    //     } else {
    //       setTimeout(() => {
    //         handlerMediaPlay(task_id, Id)
    //       }, 1000)
    //     }
    //   }
    // } catch (error) {
    // }
  }
  //---百度文字转语音---

  const handlerClickText2Mp3 = async (Id: string) => {
    try {
      setPlayLoad(true)
      setCurrentPlayIndex(Id)
      const { data, code } = await postChatTextToMedia(Id)
      if (code === 0) {
        if (audioRef.current) {
          audioRef.current.src = data.mp3_url;
        }
        playAudio(data.mp3_url, Id)
      }
      setPlayLoad(false)
    } catch (error) {
      setPlayLoad(false)
    }
  }
  const stopMedia = () => {
    if (audioRef.current) {
      setCurrentPlayIndex(null)
      audioRef.current.pause();
      audioRef.current.src = '';
    }
  }

  const playAudio = (src: string, Id: string) => {
    if (audioRef.current) {
      audioRef.current.src = src;

      audioRef.current.pause();
      audioRef.current.load();
      audioRef.current.play();

    } else {
      Toast.show('播放有误')
    }
  };
  const handleAudioEnded = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = '';
    }
    setCurrentPlayIndex(null)
  }

  // useEffect(() => {
  //   if (audioRef.current) {
  //     audioRef.current.addEventListener('', () => {

  //     })
  //   }
  // })
  //--百度------------------------------



  //--讯飞-----------------


  //--讯飞-----------------

  return <div className={styles.chatGptTop}>
    <Chat
      messages={messages}
      recorder={{
        canRecord: true,
        onStart() {
          console.log('开始录音');
          RecordStart();
        },
        onEnd() {
          console.log('停止录音');
          RecordStop();
        },
        onCancel() {
          console.log('取消录音');
        },
      }}
      messagesRef={msgRef}
      renderMessageContent={renderMessageContent}
      onSend={handleSend}
      onInputFocus={onInputFocus}
      onInputTypeChange={onInputChange}
    // onChange={onTextChange}
    >

    </Chat>
    <audio onEnded={handleAudioEnded} ref={audioRef} style={{ display: 'none', }} controls />
    {/* <div style={{ position: "absolute", zIndex: 100, top: 0 }}>
      <audio ref={audioRef} controls />
      <Button onClick={playAudio}>play</Button>

    </div> */}
  </div>
}

export default GuoHuaChat